import React, { Component, Fragment } from "react"
import { graphql } from "gatsby"
import textversionjs from "textversionjs"

import Context from "../components/Context"
import Comments from "../components/Comments"
import SEO from "../components/Seo"
import Cover from "../components/Cover"
import Description from "../components/Description"
import Parts from "../components/Parts"
import Days from "../components/Days"

import Follow from "../components/Follow"
import Pagination from "../modules/Pagination"
import Anchor from "../modules/Anchor"
import ScrollDown from "../modules/ScrollDown"
import ScrollUp from "../modules/ScrollUp"

import { slugify } from "../functions/slugify"
import { getContentfulEntry } from "../functions/apiLambda"
import { filterCommentsByEntry } from "../functions/contentfulData"
import moment from "../functions/moment"

export default class Event extends Component {
  state = {
    sections: {
      cover: "couverture",
      description: "résumé",
      parts: "parties",
      days: "jours",
      comments: "commentaires",
    },
  }

  componentDidMount() {
    if (this.context.data.sections !== this.state.sections) {
      this.context.set({ sections: this.state.sections })
    }

    const { data } = this.props
    // current page
    const event = data.event

    let allDays = []
    event.parts &&
      event.parts.forEach(part => {
        part.days &&
          part.days.forEach(day => {
            allDays = [...allDays, { ...day }]
          })
      })
    if (this.state.allDays !== allDays) {
      this.setState({ allDays })
    }

    const header = {
      currentName: event.name,
      currentType: "event",
      buttons: this.state.sections,
      counts: {
        parts: event.parts ? event.parts.length : null,
        days: allDays ? allDays.length : null,
        comments: this.state.comments ? this.state.comments.length : null,
      },
    }
    if (this.context.data.header !== header) {
      this.context.set({ header })
    }

    const { mapdata } = event
    if (this.context.data.mapdata !== mapdata) {
      this.context.set({ mapdata })
    }
  }

  componentDidUpdate() {
    const { allComments } = this.context.data

    // console.log(
    //   "EVENT ==> componentDidUpdate() ==> ",
    //   allComments,
    //   this.state.allComments
    // )

    if (allComments) {
      if (this.state.allComments !== allComments) {
        this.setState({ allComments })

        getContentfulEntry(this.props.data.event.contentful_id)
          // Filter current entry comments
          .then(entry => {
            return filterCommentsByEntry(allComments, entry)
          })
          // Add comments to state
          .then(matchComments => {
            this.setState({ comments: matchComments })
            const commentsCount = matchComments.length
            if (this.context.data.header.counts.comments !== commentsCount) {
              let header = { ...this.context.data.header }
              header.counts.comments = commentsCount
              this.context.set({ header })
            }
          })
      }
    }
  }

  render() {
    const { sections, allDays } = this.state

    const { data } = this.props
    // current page
    const event = data.event
    // previous page
    const prevEvent = data.prevEvent
    const prevUrl = prevEvent && "/" + prevEvent.slug
    // next page
    const nextEvent = data.nextEvent
    const nextUrl = nextEvent && "/" + nextEvent.slug

    return (
      <Fragment>
        {/* SEO */}

        {event && (
          <SEO
            pageTitle={event && `${event.title} : ${event.name}`}
            pageDescription={
              event.description &&
              textversionjs(event.description.childMarkdownRemark.html)
            }
            pageImage={event.thumb && event.thumb.fluid.src}
            pageUrl={this.context.data.metadata.siteUrl + "/" + event.slug}
          />
        )}

        {/* END : SEO */}

        {/* COVER */}

        <Anchor id={sections.cover} />

        <Cover
          current={event}
          prev={prevUrl}
          next={nextUrl}
          scroll={sections.description}
        />

        <Anchor id={"parallax-menu"} />

        {/* END : COVER */}

        {/* DESCRIPTION */}

        <Anchor id={sections.description} />

        <div className="uk-background-default">
          <div className="uk-section uk-position-relative">
            <div className="uk-position-top">
              <ScrollUp to={"#" + slugify(sections.cover)} />
            </div>

            <div className="uk-container uk-container-medium">
              <h3 className="uk-text-center uk-text-uppercase uk-margin-medium-bottom">
                {sections.description}
              </h3>

              <Description
                date={
                  "du " +
                  moment(event.parts[0].days[0].date).format("D MMMM YYYY") +
                  " au " +
                  moment(
                    event.parts[event.parts.length - 1].days[
                      event.parts[event.parts.length - 1].days.length - 1
                    ].date
                  ).format("D MMMM YYYY")
                }
                text={
                  event.description &&
                  event.description.childMarkdownRemark.html
                }
                youtube={event.youtube}
                thumb={event.thumb}
              />

              <div className="uk-text-center">
                <Follow
                  compact={false}
                  reference={event.contentful_id}
                  title={event.title}
                />
              </div>

              <Pagination
                prevSlug={
                  prevUrl && prevUrl + "#" + slugify(sections.description)
                }
                prevTitle={prevEvent && prevEvent.title}
                nextSlug={
                  nextUrl && nextUrl + "#" + slugify(sections.description)
                }
                nextTitle={nextEvent && nextEvent.title}
              />
            </div>

            <div className="uk-position-bottom uk-padding-small">
              <ScrollDown to={"#" + slugify(sections.parts)} />
            </div>
          </div>
        </div>

        {/* END : DESCRIPTION */}

        {/* PARTS */}

        <Anchor id={sections.parts} />

        <div className="uk-background-muted">
          <div className="uk-section uk-position-relative">
            <div className="uk-position-top">
              <ScrollUp to={"#" + slugify(sections.description)} />
            </div>

            <div className="uk-container uk-container-medium">
              <h3 className="uk-text-center uk-text-uppercase uk-margin-medium-bottom">
                {sections.parts}
              </h3>

              <Parts parts={event.parts} />

              <div className="uk-text-center">
                <Follow
                  compact={false}
                  reference={event.contentful_id}
                  title={event.title}
                />
              </div>

              <Pagination
                prevSlug={prevUrl && prevUrl + "#" + slugify(sections.parts)}
                prevTitle={prevEvent && prevEvent.title}
                nextSlug={nextUrl && nextUrl + "#" + slugify(sections.parts)}
                nextTitle={nextEvent && nextEvent.title}
              />
            </div>

            <div className="uk-position-bottom uk-padding-small">
              <ScrollDown to={"#" + slugify(sections.days)} />
            </div>
          </div>
        </div>

        {/* END : PARTS */}

        {/* DAYS */}

        <Anchor id={sections.days} />

        <div className="uk-background-default">
          <div className="uk-section uk-position-relative">
            <div className="uk-position-top">
              <ScrollUp to={"#" + slugify(sections.parts)} />
            </div>

            <div className="uk-container uk-container-medium">
              <h3 className="uk-text-center uk-text-uppercase uk-margin-medium-bottom">
                {sections.days}
              </h3>

              <Days days={allDays} />

              <div className="uk-text-center">
                <Follow
                  compact={false}
                  reference={event.contentful_id}
                  title={event.title}
                />
              </div>

              <Pagination
                prevSlug={prevUrl && prevUrl + "#" + slugify(sections.days)}
                prevTitle={prevEvent && prevEvent.title}
                nextSlug={nextUrl && nextUrl + "#" + slugify(sections.days)}
                nextTitle={nextEvent && nextEvent.title}
              />
            </div>

            <div className="uk-position-bottom uk-padding-small">
              <ScrollDown to={"#" + slugify(sections.comments)} />
            </div>
          </div>
        </div>

        {/* END : DAYS */}

        {/* COMMENTS */}

        <Anchor id={sections.comments} />

        {/* <div className="uk-overlay uk-overlay-primary uk-light uk-padding-remove"> */}
        <div className="uk-background-muted">
          <div className="uk-section uk-position-relative">
            <div className="uk-position-top">
              <ScrollUp to={"#" + slugify(sections.days)} />
            </div>

            <div className="uk-container uk-container-medium uk-animation-fade">
              <h3 className="uk-text-center uk-text-uppercase uk-margin-medium-bottom">
                {sections.comments}
              </h3>

              <Comments
                comments={this.state.comments}
                reference={event.contentful_id}
                // url={window.location.origin + window.location.pathname}
              />

              <div className="uk-text-center">
                <Follow
                  compact={false}
                  reference={event.contentful_id}
                  title={event.title}
                />
              </div>

              <Pagination
                prevSlug={prevUrl && prevUrl + "#" + slugify(sections.comments)}
                prevTitle={prevEvent && prevEvent.title}
                nextSlug={nextUrl && nextUrl + "#" + slugify(sections.comments)}
                nextTitle={nextEvent && nextEvent.title}
              />
            </div>
          </div>
        </div>

        {/* END : COMMENTS */}
      </Fragment>
    )
  }
}

Event.contextType = Context

export const query = graphql`
  query($eventId: String!, $prevEventId: String!, $nextEventId: String!) {
    event: contentfulEvent(contentful_id: { eq: $eventId }) {
      contentful_id
      name
      title
      slug
      subtitle
      cover {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      youtube
      thumb {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
      blog {
        contentful_id
      }
      mapdata
      parts {
        contentful_id
        name
        title
        slug
        cover {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid
          }
        }
        event {
          slug
        }
        days {
          contentful_id
          name
          title
          slug
          date
          cover {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid
            }
          }
          photos {
            contentful_id
            title
            fluid(maxWidth: 1920) {
              ...GatsbyContentfulFluid
            }
          }
          trace {
            content
          }
          part {
            slug
            event {
              slug
            }
          }
        }
      }
    }
    prevEvent: contentfulEvent(contentful_id: { eq: $prevEventId }) {
      slug
      title
    }
    nextEvent: contentfulEvent(contentful_id: { eq: $nextEventId }) {
      slug
      title
    }
  }
`
