import React from "react"

import Card from "../modules/Card"

export default ({ parts }) => (
  <div
    // className="uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-2@m uk-child-width-1-3@l uk-child-width-1-4@xl uk-text-center uk-flex-center"
    className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-1@m uk-child-width-1-2@l uk-child-width-1-3@xl uk-text-center uk-flex-center"
    data-uk-grid
  >
    {parts &&
      parts.map(part => {
        return (
          <div key={part.contentful_id}>
            <Card
              link={"/" + part.event[0].slug + "/" + part.slug}
              fluid={part.cover.fluid}
              title={part.title}
              label={part.name}
            />
          </div>
        )
      })}
  </div>
)
